import React from 'react';
import styled from 'styled-components';
import { compose, space, variant } from 'styled-system';

import type { ThemeDisplaySizes } from '@rover/kibble/styles';
import { A11yHidden, DSTokenMap, fontWeight, textAlign, textColor } from '@rover/kibble/styles';

import Box from '../Box';
import { HeadingTags, Props as HeadingProps } from '../Heading/Heading';

export type Props = Omit<HeadingProps, 'size'> & {
  size?: ThemeDisplaySizes;
};

export const DEFAULT_TAGS_FOR_SIZES: Record<ThemeDisplaySizes, HeadingTags> = {
  '400': 'h3',
  '500': 'h2',
  '600': 'h1',
};
export const getTag = (size: ThemeDisplaySizes, as?: HeadingTags): HeadingTags => {
  if (as) {
    return as;
  }

  return DEFAULT_TAGS_FOR_SIZES[size];
};
const StyledDisplay = styled(Box)<Omit<Props, 'as'>>(
  // Resets values in case of global styles
  {
    padding: '0',
    margin: '0',
    border: '0 none',
    'font-weight': '400 !important', // prevent faux bolding in some browsers, have to use the dash-case version to avoid odd interactions with styled-system
  },
  (props) =>
    variant({
      prop: 'size',
      variants: {
        400: {
          fontFamily: `${DSTokenMap.DISPLAY_400_FONT_FAMILY} !important`,
          fontSize: props.responsive
            ? [DSTokenMap.DISPLAY_400_FONT_SIZE, DSTokenMap.HEADING_RESPONSIVE_400_FONT_SIZE]
            : DSTokenMap.DISPLAY_400_FONT_SIZE,
          lineHeight: DSTokenMap.DISPLAY_400_LINE_HEIGHT,
          marginBottom: DSTokenMap.DISPLAY_400_MARGIN_BOTTOM,
        },
        500: {
          fontFamily: `${DSTokenMap.DISPLAY_500_FONT_FAMILY} !important`,
          fontSize: props.responsive
            ? [DSTokenMap.DISPLAY_500_FONT_SIZE, DSTokenMap.DISPLAY_RESPONSIVE_500_FONT_SIZE]
            : DSTokenMap.DISPLAY_500_FONT_SIZE,
          lineHeight: DSTokenMap.DISPLAY_500_LINE_HEIGHT,
          marginBottom: DSTokenMap.DISPLAY_500_MARGIN_BOTTOM,
        },
        600: {
          fontFamily: `${DSTokenMap.DISPLAY_600_FONT_FAMILY} !important`,
          fontSize: props.responsive
            ? [DSTokenMap.DISPLAY_600_FONT_SIZE, DSTokenMap.DISPLAY_RESPONSIVE_600_FONT_SIZE]
            : DSTokenMap.DISPLAY_600_FONT_SIZE,
          lineHeight: DSTokenMap.DISPLAY_600_LINE_HEIGHT,
          marginBottom: DSTokenMap.DISPLAY_600_MARGIN_BOTTOM,
        },
      },
    })(props),
  (props) => (props.a11yHidden ? `${A11yHidden}` : ''),
  compose(textAlign, textColor, fontWeight, space) // Apply last to avoid being overwritten by resets and variant styles
);

function Display({
  a11yHidden = false,
  as,
  responsive = true,
  size = '600',
  ...other
}: Props): JSX.Element {
  return (
    <StyledDisplay
      {...other}
      as={getTag(size, as)}
      a11yHidden={a11yHidden}
      responsive={responsive}
      size={size}
    />
  );
}

export default Display;
