import React from 'react';
import styled from 'styled-components';

import { MQ } from '@rover/kibble/styles';
import TabbedNavigation from '@rover/react-lib/src/components/navigation/TabbedNavigation';

const HiddenXSSpan = styled.span`
  ${MQ.XS.toString()} {
    display: none;
  }
`;
export default (): JSX.Element => (
  <TabbedNavigation
    justifyContent="center"
    tabs={[
      {
        linkPath: '/careers/',
        label: 'Careers',
      },
      {
        linkPath: '/careers/engineering/',
        label: 'Engineering',
      },
      {
        linkPath: '/careers/core-values/',
        label: (
          <span>
            <HiddenXSSpan>Core </HiddenXSSpan>
            Values
          </span>
        ),
      },
      {
        linkPath: '/careers/perks-and-culture/',
        label: (
          <span>
            Perks
            <HiddenXSSpan> & Culture</HiddenXSSpan>
          </span>
        ),
      },
      {
        linkPath: '/careers/search/',
        label: (
          <span>
            <HiddenXSSpan>Search </HiddenXSSpan>
            Jobs
          </span>
        ),
      },
    ]}
  />
);
