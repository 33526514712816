import React from 'react';
import { Link } from 'react-router-dom';

// the LinkProps from react-router-dom don't work right? so we recreate the important parts
export type Props = {
  children: React.ReactNode;
  className?: string; // supports styled-components
  to: string;
};

const RouterLink = (props: Props): JSX.Element => <Link {...props} />;

/**
 * Helper for when you want to use `RouterLink` in the `as` prop of a Kibble component, ex: `<Button as={asRouterLink('/careers/search')} />`
 * */
export const asRouterLink =
  (to: string) =>
  // this highlights that when used in the `as` prop it can be passed arbitrary props that TS isn't perfect about catching
  ({ className, children }: Record<string, unknown> & Omit<Props, 'to'>): JSX.Element =>
    (
      <RouterLink className={className} to={to}>
        {children}
      </RouterLink>
    );

export default RouterLink;
