import { useEffect } from 'react';
import { QueryClient, useQuery, UseQueryResult } from '@tanstack/react-query';
import uuid from 'uuid/v4';

import appendUrlParams from '@rover/rsdk/src/modules/Network/appendUrlParams';
import { CookiePurpose, createCookie, getCookie } from '@rover/rsdk/src/modules/Network/cookies';
import httpContext from '@rover/rsdk/src/modules/Network/httpContext';
import { HTTP } from '@rover/rsdk/src/modules/Network/orvalHTTPInterface';
import getUrlParameter from '@rover/utilities/getUrlParameter';

// CONSTANTS & CONFIG //
export const LEVER_FETCH_URL = 'https://api.lever.co/v0/postings/rover?group=team&mode=json';
export const LEVER_PARAM_KEYS = ['lever-origin', 'lever-source'];
export const leverJobsRetriveQueryKey = ['lever-jobs'];

// TYPES //
export type LeverJobPosting = {
  additional?: string;
  additionalPlain?: string;
  applyUrl?: string;
  categories: { commitment?: string; department?: string; location: string; team?: string };
  country?: string;
  createdAt?: number;
  description?: string;
  descriptionPlain?: string;
  hostedUrl: string;
  id?: string;
  lists?: Array<{
    content: string;
    text: string;
  }>;
  text: string;
  workplaceType?: string;
};
export type LeverJobPostingCategory = {
  title: string;
  postings: LeverJobPosting[];
};
export type FullJobsResponse = LeverJobPostingCategory[];
export type TruncatedJobsResponse = Array<{
  title: LeverJobPostingCategory['title'];
  postings: Array<{
    categories: Pick<LeverJobPosting['categories'], 'location'>;
    hostedUrl: LeverJobPosting['hostedUrl'];
    text: LeverJobPosting['text'];
  }>;
}>;
export type JobTeam = {
  title: string;
  id: string;
  links: Array<{
    text: string;
    url: string;
    descriptor: string;
  }>;
};

// UTILS //
/**
 * Looks for the LEVER_PARAM_KEYS as query params and sets them as cookies
 */
export const setLeverParamCookies = (): void => {
  LEVER_PARAM_KEYS.forEach((leverParamKey) => {
    const leverParamValue = getUrlParameter(leverParamKey);
    if (leverParamValue) {
      createCookie(CookiePurpose.StrictlyNecessary, leverParamKey, leverParamValue, 30);
    }
  });
};

/**
 * Gets jobs postings from the lever api, modeled after the functions in rsdk/src/apiClient/
 */
export const leverJobsRetrive = async (): Promise<TruncatedJobsResponse> => {
  const fetchId = uuid();
  const PROFILER = httpContext.getProfiler();
  const PROFILER_KEYS = httpContext.getProfilerKeys();
  PROFILER?.start({
    key: PROFILER_KEYS?.API_CALL || 'unknown',
    uuid: fetchId,
  });

  const result = await HTTP<FullJobsResponse>({
    method: 'get',
    url: LEVER_FETCH_URL,
    isRoverUrl: false,
  });

  const tags = { apiMethod: 'get', apiUrl: LEVER_FETCH_URL };

  PROFILER?.end({
    key: PROFILER_KEYS?.API_CALL || 'unknown',
    uuid: fetchId,
    tags,
  });

  // trim unneeded data from the response, lightens the dehyrdated SSR state
  const filteredJobs: TruncatedJobsResponse = result.map((j) => ({
    title: j.title,
    postings: j.postings.map((p) => ({
      text: p.text,
      hostedUrl: p.hostedUrl,
      categories: {
        location: p.categories.location,
      },
    })),
  }));
  return filteredJobs;
};

/**
 * For use on the SSR Server in `reactQueryPrefetch` functions
 */
export const prefetchLeverJobs = async (queryClient: QueryClient): Promise<void> => {
  await queryClient.prefetchQuery(leverJobsRetriveQueryKey, leverJobsRetrive);
};

/**
 * Modfies the Lever Jobs API results to include the LEVER_PARAM_KEYS, if they are set as cookies
 */
const addLeverCookieParamsToLeverPostingUrls = (
  data: TruncatedJobsResponse | undefined
): TruncatedJobsResponse | undefined => {
  if (!data) return undefined;
  // get a map of lever cookies
  const leverCookies: Record<string, string> = LEVER_PARAM_KEYS.reduce(
    (accumulator, leverParamKey) => {
      const value = getCookie(leverParamKey);
      return value ? { ...accumulator, [leverParamKey]: value } : accumulator;
    },
    {}
  );
  const filteredJobsWithFullUrls: TruncatedJobsResponse = data.map(({ postings, ...job }) => ({
    ...job,
    postings: postings.map(({ hostedUrl, ...posting }) => ({
      ...posting,
      hostedUrl: appendUrlParams(hostedUrl, leverCookies),
    })),
  }));
  return filteredJobsWithFullUrls;
};

export const getSnakedString = (s: string): string => s.replace(/[^a-zA-Z0-9]/g, '_');

// HOOKS //
/**
 * Provides a react-query interface for the Lever Jobs API
 */
export const useLeverJobs = (): UseQueryResult<TruncatedJobsResponse> => {
  const queryResult = useQuery(leverJobsRetriveQueryKey, leverJobsRetrive, { staleTime: Infinity });
  return {
    ...queryResult,
    data: addLeverCookieParamsToLeverPostingUrls(queryResult.data),
  } as UseQueryResult<TruncatedJobsResponse>; // The as is necessary to ensure we return a UseQueryResult and not an arbitrary object type
};

/**
 * Returns an array of Lever job categories, intended to power the options in a SelectPrompt
 */
export const useJobHeaderTeams = (): Array<{
  title: string;
  value: string;
}> => {
  const { data } = useLeverJobs();
  if (!data) return [];
  return data.map(({ title }) => ({
    title,
    value: title,
  }));
};

/**
 * Returns the specific data structure expected by the UI in SearchJobsPage/components/JobsListSection.tsx
 */
export const useJobTeams = (): JobTeam[] => {
  const { data } = useLeverJobs();
  if (!data) return [];
  return data.map(({ title, postings }) => ({
    title,
    id: getSnakedString(title),
    links: postings.map((posting) => ({
      text: posting.text,
      url: posting.hostedUrl,
      descriptor: posting.categories.location,
    })),
  }));
};

/**
 * Returns the specific data structure expected by the UI in EngineeringPage/components/JobsSection.tsx
 */
export const useEngineeringJobs = (): JobTeam | undefined => {
  const jobTeams = useJobTeams();
  return jobTeams
    .filter((team) => team.title === 'Technology' || team.title === 'Engineering')
    .pop();
};

/**
 * Calls setLeverParamCookies "onMount"
 */
export const useSetLeverParamCookies = (): void => {
  useEffect(() => {
    setLeverParamCookies();
  }, []);
};
