import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Text } from '@rover/kibble/core';
import getPath from '@rover/shared/js/utils/getPath';

import RouterLink from '../../utils/RouterLink';

const linkStyle = ({ $active }: { $active: boolean }) =>
  css`
    color: inherit;
    text-decoration: none;

    &:hover {
      color: default;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: inherit !important;
    }

    ${$active
      ? css`
          &:hover,
          &:focus {
            cursor: default;
          }
        `
      : ''}
  `;

export const Tab = styled.a<{ $active: boolean }>`
  ${linkStyle}
`;

export const LinkTab = styled(RouterLink)<{ $active: boolean }>`
  ${linkStyle}
`;

interface BaseTabProps {
  label: React.ReactNode;
}

interface TabWithUrlProps extends BaseTabProps {
  url: string;
  linkPath?: never;
}

interface TabWithLinkPathProps extends BaseTabProps {
  url?: string;
  linkPath: string;
}

export type TabProps = TabWithUrlProps | TabWithLinkPathProps;

export type Props = React.ComponentProps<typeof Flex> & {
  tabs: TabProps[];
  highlightActiveTab?: boolean;
  sxTab?: Record<string, string | number | undefined | Array<string | number | undefined>>;
  sxText?: Record<string, string | number | undefined | Array<string | number | undefined>>;
};

export const TabbedNavigation = ({
  tabs,
  sxTab = {},
  sxText = {},
  highlightActiveTab = true,
  ...containerProps
}: Props): JSX.Element => {
  const pathname = getPath();

  return (
    <Flex as="ul" py="3x" sx={{ paddingInlineStart: 0 }} {...containerProps}>
      {tabs.map(({ url, linkPath, label }) => {
        const isActive = highlightActiveTab && (url === pathname || linkPath === pathname);
        const text = (
          <Text
            py="3x"
            px={['2x', '5x']}
            size="100"
            fontWeight={isActive ? 'semibold' : 'regular'}
            sx={{ display: 'block' }}
            {...sxText}
          >
            {label}
          </Text>
        );
        return (
          <Box
            as="li"
            key={url || linkPath}
            borderBottomWidth="2px"
            borderBottomStyle="solid"
            borderBottomColor={isActive ? 'interactive_border.primary_active' : 'border.primary'}
            sx={{ listStyleType: 'none', ...sxTab }}
          >
            {linkPath ? (
              <LinkTab to={linkPath} $active={isActive}>
                {text}
              </LinkTab>
            ) : (
              <Tab href={url} $active={isActive}>
                {text}
              </Tab>
            )}
          </Box>
        );
      })}
    </Flex>
  );
};
export default TabbedNavigation;
